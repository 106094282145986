.loginImageContainer {
  background-image: url('../images/maxwell-vert.png');
  width: 400px;
  background-color: #fff;
  margin: 0px auto;
  display: block;
  position: relative;
  height: 155px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 60px 50%;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

  border-bottom: 3px solid $maxwell-blue;
}

.loginContainer {
  width: 400px;
  padding: 25px;
  min-height: 300px;
  background-color: #eaeaec;
  margin: 0px auto;
  display: block;
  position: relative;
  input {
    height: 50px;
    border: 1px solid #ccc;
  }
  h1 {
    font-size: 1.1em;
    line-height: 1.5em;
    font-weight: 600;
    text-align: center;
    color: #777;
    margin-top: 0;
    margin-bottom: 15px;
    text-transform: none;
  }

  &.loginContainer-higher {
    height: 342px;
  }
  &.loginContainer-higher-alert {
    height: 455px;
  }

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

  .shopping-button,
  .hradmin-button {
    border: 0;
    border-radius: 3px;
    background-color: #0d596d;
    padding: 12px;
    margin-bottom: 15px;
    color: #fff;
  }
  a {
    float: left;
    color: #033b4b;
  }

  .popover.top {
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    opacity: 0.9 !important;
    float: none;
    margin: -10px 0px 0px 0px;
    position: absolute;
  }

  .left-inner-addon {
    position: relative;
  }

  .left-inner-addon input {
    padding-left: 30px;
  }

  .left-inner-addon i {
    position: absolute;
    padding: 18px 0px 16px 13px;
    pointer-events: none;
  }

  .popover.top .fa {
    position: relative;
    margin: 3px 4px 3px -3px;
    padding: 0px;
  }

  .fa-times {
    color: #ad3b4a;
  }

  .fa-check {
    color: #83a243;
  }
}

.password-reset-message {
  color: rgb(21, 86, 104);
  margin-bottom: 20px;
  margin-top: -15px;

  h3 {
    color: rgb(21, 86, 104);
    text-transform: none;
  }
}

.disaster-message {
  padding: 15px 25px 50px;
}

.password-reset-form {
  .password-requirements {
    color: $mh-light-text;
    font-weight: 300;
    border: none;

    ul {
      padding: 0 15px 0 15px;
      margin: none;
      font-size: 0.9em;
      font-weight: 300;
    }

    &.alert-danger {
      color: #a94442;
      background-color: #f2dede;
      border: 1px solid #ebccd1;
    }
  }

  #passwordSubmit {
    margin-bottom: 0;
  }

  #password,
  #confirmPassword {
    padding-left: 15px;
    padding-right: 15px;
  }
}
