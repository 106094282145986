$navLinkColor: #0d596d;
$navLinkHover: #0d596d;

.member-nav {
  background-color: $shopping-background;
  height: 125px;
  margin-bottom: 0;

  .top-bar {
    background-color: #0d596d;
    height: 20px;
    width: 100%;
  }

  .logo {
    float: left;

    img {
      max-height: 34px;
      max-width: 305px;
    }
  }
}

.member-nav-top {
  height: 105px;
  margin: 0 auto;
  padding: 30px 0;
  width: 1000px;
}

.member-nav-logo-wrapper,
.member-nav-links-wrapper {
  display: inline-block;
  height: 45px;
  line-height: 45px;
  vertical-align: middle;
  width: 45%;
}

.member-nav-logo-wrapper {
  // padding: 24px 0px;
  .logo {
    img {
      max-height: 60px;
    }
  }
}

// https://css-tricks.com/snippets/css/a-guide-to-flexbox/
// http://codepen.io/philhoyt/pen/qbzhJ
.member-nav-links-wrapper {
  width: 54%;

  ul {
    @extend %flexbox;
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    @include align-content(flex-end);

    line-height: 45px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;

    li {
      height: 45px;
      position: relative;

      &.member-nav-links-help {
        width: 35px;
      }

      &.member-nav-links-acct {
        margin: 0 45px 0 45px;

        .fa-angle-down {
          margin-left: 5px;
        }
      }

      &:hover > ul {
        display: block;
      }

      &:hover > a {
        color: $navLinkHover;
        cursor: pointer;
        font-weight: 700;
      }

      &.my-cart {
        background: #e3f3f6;
        border: 1px solid #c7cbd6;
        color: #0d596d;
        font-weight: 700;
        text-transform: uppercase;
        width: 160px;

        &:hover {
          background: #e3f3f6;
          border: 1px solid #5db9d1;
          color: #0d596d;
        }

        .fa-shopping-cart {
          margin-right: 10px;
        }

        .member-nav-cart {
          margin: 0 5px;
        }

        a {
          color: #0d596d;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    a {
      @include align-items(center);
      @include justify-content(center);
      @extend %flexbox;

      color: $black;
      font-size: 14px;
      font-weight: 400;
      line-height: 45px;
      padding: 0;
      text-decoration: none;
    }

    // secondary
    ul {
      background: #fff;
      border: 0;
      display: none;
      left: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      width: auto;

      li {
        float: none;
        width: 200px;

        &.member-nav-acctdrop {
          border-top: 5px solid #0d596d;
          top: -5px;
          width: 150px;
        }
      }

      a {
        @include justify-content(flex-start);
        border: 1px solid #c7cbd6;

        display: block;
        line-height: 140%;
        padding: 12px 15px;
        ppadding: 9px 0;
        text-align: left;
        text-transform: uppercase;
      }
    } // end of secondary ul for dropdown

    .arrow-up {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid #0d596d;
      position: relative;
      top: -5px;
      height: 0;
      width: 0;

      left: 10px;
    }
  } // end of parent ul
} // end of member-nav-links-wrapper
