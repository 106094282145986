.documents-edit-modal .modal-header h3 {
  margin: 0;
}
.lockboxLayout {
  #basicActions {
    margin-bottom: 10px;
    padding-top: 2px;
  }

  .btn.btn-info {
    cursor: default;
  }

  .alert.alert-danger.emptyState,
  .alert.alert-info.emptyState {
    margin-bottom: 0;
  }

  // panel background color hr vs member
  .panel.eShopping {
    background: $shopping-checkout-blue-dark;
    a {
      color: $shopping-dark-blue;
    }
  }

  .panel.eShopping ul {
    list-style-type: none;
  }

  // tree border styling hr vs member
  .panel ul ul:before,
  .panel.eShopping ul ul:before {
    content: '';
    display: block;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-right: 1px solid;
    padding-left: 20px;
  }
  .panel ul ul li:before,
  .panel.eShopping ul ul li:before {
    content: '';
    display: block;
    width: 10px;
    height: 0;
    border-top: 1px solid;
    position: absolute;
    top: 21px;
    left: 0;
  }
  .panel ul ul:before,
  .panel ul ul li:before {
    color: $shopping-light-gray;
  }
  .panel.eShopping ul ul:before,
  .panel.eShopping ul ul li:before {
    color: $shopping-background;
  }
  .panel ul ul li:last-child:before,
  .panel.eShopping ul ul li:last-child:before {
    height: auto;
    top: 21px;
    bottom: 0;
  }
  .panel ul ul li:last-child:before {
    background: white;
  }
  .panel.eShopping ul ul li:last-child:before {
    background: $shopping-checkout-blue-dark;
  }

  #lockboxFiles {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.panel-body {
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 7.5px;
    }
    li {
      margin: 0;
      padding: 7.5px;
    }

    .singleLockboxItem {
      background: white;
      box-shadow: 0 2px 8px #c7cbd6;
      -moz-box-shadow: 0 2px 8px #c7cbd6;
      -webkit-box-shadow: 0 2px 8px #c7cbd6;
      height: 215px;
      position: relative;
    }

    .overlay {
      display: none;
    }
    .overlay h3 {
      margin: 0;
      text-align: center;
      line-height: 200px;
      color: white;
      font-style: italic;
    }
    .showOverlay .overlay {
      cursor: pointer;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(93, 185, 209, 0.7);
      z-index: 5;
    }
    .showOverlay .text-left {
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px);
    }
    .delete {
      cursor: pointer;
      right: 9px;
      top: 4.5px;
      position: absolute;
      z-index: 10;
    }
    .showOverlay .delete {
      color: white;
    }
    .text-left {
      height: 175px;
      padding: 9px;
    }
    .file-info {
      width: 100%;
    }
    .title {
      margin: 0;
      color: $maxwell-dark-blue;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 15px;
      white-space: nowrap;
    }
    .description {
      font-size: 16px;
      padding: 9px 0 9px 0;
      max-height: 120px;
      word-wrap: break-word;
      overflow: hidden;
    }

    .text-right {
      height: 40px;
      padding: 9px;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
    .text-right span {
      font-weight: 300;
    }
  }
}
