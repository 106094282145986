#welcomeModalBody {
  .modal-body {
    overflow: scroll;
  }
}

.modal-content div {
  border-bottom: 0px;
  border-top: 0px;
}

#welcomeModalDialog {
  width: 800px;
}
#welcomeTabs {
  margin: 0 auto;
  position: relative;
  width: 730px;
}
nav.navbar.welcomeNav {
  margin-bottom: 0px;
}
#welcomeMessage {
  margin-bottom: 20px;
  text-align: left;
}
#welcomeBody {
  position: relative;
  padding: 20px 60px;

  background: #fff;
  border: 1px solid $maxwell-blue;
  width: 800px;
  margin: 0px auto;
  text-align: center;
  h1,
  h2 {
    text-align: center;
    font-size: 28px;
    color: $maxwell-dark-blue;
    margin-bottom: 25px;
  }
  img {
    margin-bottom: 10px;
  }
  .dependent {
    h4 {
      color: $maxwell-dark-blue;
    }
    margin: 25px 0px;
  }
  .welcomeButtons {
    margin-top: 25px;
  }
  .addDependent {
    font-size: 18px;
    &:hover {
      text-decoration: none;
      color: $maxwell-dark-blue;
    }
  }
  .removeDependent {
    font-size: 14px;
    &:hover {
      text-decoration: none;
      color: $maxwell-dark-blue;
    }
  }
  .spinner {
    height: 43px;
    width: 198px;
    position: relative;
    margin: -44px 0px auto 370px;
    padding-top: 6px;
  }
}
#welcomeAdvancedModalBody {
  height: 100%;
  .modal-body {
    overflow: scroll;
    padding-left: 50px;
  }
  .modal-header {
    padding: 0px;
  }
  .arrows button {
    width: 100px;
    font-size: 14px;
    padding-left: 40px;
    height: 35px;
    &:before {
      border-top: 18px #e4e4e4 solid;
      border-bottom: 19px #e4e4e4 solid;
      border-left: 12px #f7fdff solid;
    }
    &:after {
      border-top: 18px #f7fdff solid;
      border-bottom: 19px #f7fdff solid;
      border-left: 12px #e4e4e4 solid;
    }
  }
  .current {
    &:before {
      border-top: 18px $shopping-light-blue-background solid !important;
      border-bottom: 19px $shopping-light-blue-background solid !important;
    }
    &:after {
      border-left: 12px solid $shopping-light-blue-background !important;
    }
  }

  .completed {
    &:before {
      border-top: 18px $shopping-soft-blue-background solid !important;
      border-bottom: 19px $shopping-soft-blue-background solid !important;
    }
    &:after {
      border-left: 12px solid $shopping-soft-blue-background !important;
    }
  }
  .arrows {
    margin-left: 50px;
    margin-bottom: 0px;
  }
}

#welcomeAdvancedModalDialog {
  width: 900px;
  .modal-body {
    padding-top: 0px;
    button,
    a.shopping-button {
      display: inline-block;
      width: 160px;
      text-align: center;
    }
  }
}

#decision-welcome-wrapper {
  height: 100%;
  text-align: center;
  overflow: hidden;
  h2 {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    padding-left: 16px;
  }
  .info-icon {
    vertical-align: middle;
    max-height: 50px;
    max-width: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.progressCircles {
  margin: 10px auto;
  display: block;
  width: 70px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.circle {
  width: 10px;
  height: 10px;
  background-color: transparent;
  margin: 3px;
  padding: 0px;
  border-radius: 5px;
  border: 2px solid $maxwell-blue;
  float: left;
  &.filled {
    border: none;
    background-color: $maxwell-blue;
  }
}
.checkbox {
  &.employer-custom-field {
    margin-top: 0.8em;
  }
}
.decision {
  padding-top: 5px;
  padding-bottom: 5px;
}
.decisionFooter {
  margin-bottom: 30px;
}
#decisionPrevious {
  margin-right: 25px;
}
#decisionComplete {
  margin-left: 25px;
}

#yourSituation {
  h5 {
    color: black;
    text-transform: none;
  }
}
