.wallet {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #083c4a;
    font-family: Lato, Helvetica, Arial, sans-serif;
    margin: 0px;
  }

  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }

  h1 {
    font-style: italic;
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    text-transform: none;
    font-size: 18px;
  }

  h5 {
    text-transform: none;
    text-align: center;
    font-weight: bold;
  }

  label {
    font-weight: normal;
  }

  .container {
    width: 600px;
  }

  .form-control {
    width: 350px;
  }

  .modal-buttons {
    width: 200px;
  }

  .error {
    color: $maxwell-red;
  }

  &.form-container {
    width: 600px;
    padding: 30px;
  }
}

.cancelOrSubmit {
  padding-top: 15px;
}

.accountSelector {
  padding: 10px;
}
