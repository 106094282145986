@import 'common/_colors';
@import 'common/_login-form';
@import '~bourbon/app/assets/stylesheets/_bourbon';
@import 'lockbox';
@import 'arrows';
@import 'member/member-partials';
@import 'member/components/_modal.scss';
@import 'mixins/flexboxmixins';
@import 'mm401k';

$fa-font-path: 'font/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/v4-shims';
// For backwards compatibility, alias 'Font Awesome 5 Free' as 'FontAwesome'
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
    url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

html {
  height: auto;
  height: 100%;
  position: relative;
}

body {
  background-color: $shopping-background;
  font-family: Lato, Helvetica, Arial, sans-serif;
  height: auto;
  min-height: 100%;
  padding-bottom: 160px;
  padding-top: 122px;
  position: relative;
}

.inlineButtons {
  button,
  .btn {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

// this is going away
#cartPopover {
  background: $shopping-checkout-blue-light;
  border: 1px solid $maxwell-blue;
  left: 500px;
  padding: 20px;
  position: absolute;
  top: 80px;
  width: 400px;
  z-index: 10294810928401;

  hr {
    border-top: 1px solid #333;
    margin-bottom: 10px;
    margin-top: 0;
  }

  h5 {
    margin-top: 1px;
  }

  .cartScroll {
    border: 1px solid #aaa;
    height: 150px;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px 20px;

    .rightJustify {
      text-align: right;
    }
  }
}

.fixed-pos {
  position: fixed !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0c3c49;
  font-family: Lato, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.container-center {
  height: 85%;
  margin: 0 auto;
  position: relative;
  width: 1000px;
}

a {
  color: $maxwell-soft-blue;
}

a,
button {
  outline: none !important;
}

p {
  font-weight: 300;
}

i {
  vertical-align: 0% !important;
}

a:hover {
  text-decoration: underline;
}

hr {
  border-top: 1px solid $maxwell-blue;

  &.react-component {
    border-top: 1px solid $maxwell-blue !important;
    margin: 20px 10px;
  }
}

hr.react-component {
  border-top: 1px solid $maxwell-blue !important;
  margin: 20px;
}

.blue-border {
  border: solid 1px $maxwell-blue;
}

.clickable {
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.text-align-center {
  text-align: center;
}

.panel-heading span {
  font-size: 22px;
}

a.clickable {
  color: inherit;
}

a.clickable:hover {
  text-decoration: none;
}

.datepicker {
  z-index: 1151;
}

.red {
  color: $maxwell-red;
}

.green {
  color: $maxwell-green;
}

.shoppingCartTotal {
  margin-left: 10px;
}

h3 {
  color: $shopping-button-dark-blue-background;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-bottom: 1px solid #999;
  border-top: 0;
}

.table > tbody > tr:last-child > td {
  border-bottom: 0px;
}

td.blue-text {
  color: $maxwell-blue;
  text-transform: uppercase;
}

.plainText {
  color: $white;
  text-decoration: none;
}

.footer-bottom {
  background-color: $shopping-footer-background;
  color: $shopping-footer-text;
  height: 100px;
  margin-top: 40px;
  padding-top: 20px;
}

// .container-center {
//   overflow:hidden;
// }
.small-text {
  font-size: 12px;
}

.employer_logo,
.employer_logo img {
  max-height: 65px;
  max-width: 150px !important;
}

.has-error {
  border: solid 1px $maxwell-red;
}

.responsive-background {
  min-width: 900px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1000;

  img {
    min-width: 900px;
    width: 100%;
  }
}

input.has-error {
  border: 1px solid $maxwell-red;
}

.benefitsSummaries {
  width: 100%;

  tr {
    font-size: 12pt;
    height: 35px;
    line-height: 35px;
  }

  td {
    border: 0;
    font-size: 10pt;
    height: 50px;
    line-height: 50px;
  }
}

.versionHeader {
  background-color: $red;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: $white;
  font-weight: bold;
  height: 25px;
  left: 50%;
  line-height: 25px;
  margin-left: -500px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 1000px;
  z-index: 9999;
}

.versionHeader a {
  color: $white;
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline !important;
}

.viewMoreInfo {
  position: absolute;
  right: -10px;
  top: -10px;
}

.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

.popover.top {
  display: block;
  float: left;
  margin: 20px;
  max-width: 100%;
  position: relative;
  width: 90%;
  z-index: 997;
}

.progress {
  background-color: #cccccc;
  height: 13px;
}

.dropdown-menu {
  background-color: $shopping-dark-blue;
  border-radius: 0;
  color: $white;
  min-width: 50px !important;
  position: relative;
  text-align: right;
  text-transform: uppercase;
}

.dropdown-menu > li > a {
  color: $white;
  padding: 7px 15px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: transparent;
  color: $maxwell-blue;
  text-decoration: none;
}

ul.nav li.dropdown:hover > ul.dropdown-menu {
  display: block;
  width: 100%;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}

#personalForm {
  .intro {
    display: inline-block;
    padding-top: 20px;
  }
}

.error-red-text {
  color: $red;
}

#qleForm {
  label {
    font-weight: normal;

    &.notification {
      margin-bottom: 7px;
    }
  }
}

.qle-confirm-nointerdate {
  margin-top: -4px;
}

.word-wrap {
  word-wrap: break-all;
  word-wrap: break-word;
}

// this is on the #personal view
.editProfile {
  float: right;
}

.hidePopover {
  background-image: url('/images//site_wide.sprite.png');
  background-position: -200px 0;
  background-size: 300px;
  cursor: pointer;
  height: 42px;
  margin-top: -15px;
  width: 34px;
}

.hideAlertBox {
  background-image: url('/images//site_wide.sprite.png');
  background-position: -200px 0;
  background-size: 300px;
  height: 42px;
  width: 34px;
}

.metrics-box {
  // background: white;
  // padding: 10px;
  height: 105px;
  margin: 0 20px 10px 20px;
  text-align: right;

  .title {
    display: block;
    // font-weight: bold;
    font-size: 16px;
  }
  .amount {
    display: block;
    font-size: 24px;
    line-height: 1.7em;
  }
  .as-of-date {
    font-size: 12px;
    color: #555;
  }

  border-bottom: 1px solid $maxwell-blue;
}

.deferral-amounts {
  h5 {
    font-weight: bold;
  }

  .deferral-amount-row {
    line-height: 1.8em;
  }

  hr {
    border-color: $shopping-soft-gray;
  }
}

.deferrals-box {
  margin-top: 25px;
  .btn.deferral {
    width: 37.5px;
  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .deferral {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .bigNumber {
    margin-bottom: -10px !important;
  }
}

.buttons {
  padding-top: 20px;
}

i.cost-warning {
  color: $maxwell-orange;
  font-size: 1.25em;
}

//Overwrite default jquery-ui tooltip styling
.ui-tooltip {
  font-size: 1em;
  font-family: Lato, sans-serif;
  border-width: 1px;
  border-radius: 1px;
  background: none;
  background-color: white;
}

// @TODO: Replace all jquery-ui tooltips with bootstrap js tooltips (design approved!):

// .white-block-tooltip {
//   border: 1px solid gray;
//   min-width: 550px;
//   background: #FFFFFF;
//   color: #000000;
//   font-size: 14px;
//   text-align: left;
//   padding: 8px;
//   line-height: 1.3;
//   opacity: 1.0;
//   box-shadow: 1px 1px 2px #000000;
// }

// Something like this to overwrite bootstrap default styling:

// .tooltip-arrow {
//   border: 0px;
// }

// .tooltip.bottom .tooltip-inner {
//   background-color: white;
//   opacity: 1.0;
//   margin: 0px;
//   padding: 0px;
// }

// .tooltip.in {
//   opacity: 1;
//   filter: alpha(opacity=100);
// }
