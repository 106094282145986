#main-nav-wrapper {
  // border-bottom: 1px solid #0d596d;
  // border-top: 1px solid #0d596d;
  // margin-top: 3px;
}

.main-nav {
  @extend %flexbox;
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  @include align-content(flex-start);

  border-bottom: 1px solid #5db9d1;
  border-top: 1px solid #5db9d1;

  line-height: 40px;
  list-style: none;
  margin: 3px auto;
  padding: 0;
  position: relative;
  width: 1000px;

  li {
    height: 40px;
    position: relative;

    line-height: 40px;
    margin-right: 10px;
    padding: 0 5px;

    // &:hover {
    //   background: #f4f4f7;
    // }

    &.active {
      border-bottom: 1px solid #0d596d;
      border-top: 1px solid #0d596d;
    }

    &:hover > ul {
      display: block;
    }

    &:hover > a {
      // color: $navLinkHover;
      cursor: pointer;
      font-weight: 700;
    }
  }

  & > li {
    &:nth-of-type(1) {
      width: 170px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-right: 13px;
      width: 130px;
    }
  }

  a {
    @include align-items(center);
    @include justify-content(center);
    @extend %flexbox;

    // color: $black;
    // font-size: 14px;
    // font-weight: 400;
    // line-height: 45px;
    // padding: 0;
    // text-decoration: none;

    color: $black;
    display: block;
    text-align: center;

    // &:nth-of-type(1) {
    //   margin-left: 10px;
    // }

    &:hover,
    &.active {
      color: #5db9d1;
      cursor: pointer;
      // font-size: 16px;
      font-weight: 700;
      text-decoration: none;
    }
  }

  & > a {
    margin-left: 10px;
  }

  // secondary
  ul {
    background: #fff;
    border: 0;
    border-top: 5px solid #0d596d;
    display: none;
    left: 0;
    padding: 0;

    position: absolute;
    top: 100%;
    width: auto;
    width: 170px;
    z-index: 9999;

    // border-left: 1px solid #c7cbd6;
    // border-right: 1px solid #c7cbd6;
    // border-bottom: 1px solid #c7cbd6;

    li {
      // border-top: 1px solid #c7cbd6;
      // border-left: 1px solid #c7cbd6;
      // border-right: 1px solid #c7cbd6;
      border: 1px solid #c7cbd6;
      float: none;
      list-style: none;
      padding: 0;
      // top: -5px;
      width: 170px;

      &:nth-of-type(1) {
        margin-top: -5px;
        // border-top: 5px solid #0d596d;
        // height: 45px;
      }
      &:nth-child(odd) {
        border-top: 0;
      }

      &:hover {
        background: #f4f4f7;

        a {
          color: #0d596d;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }

    a {
      @include justify-content(flex-start);
      // border: 1px solid #c7cbd6;
      color: #0d596d;
      display: block;
      font-size: 15px;
      line-height: 140%;
      margin-left: 0;
      padding: 12px 15px;
      padding: 9px 0;
      text-align: center;
    }
  } // end of secondary ul for dropdown

  .arrow-up {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #0d596d;
    position: relative;
    top: -10px;
    height: 0;
    width: 0;
    margin: 0 auto;
    // left: 10px;
  }
}

// .main-nav {
//   @extend %flexbox;
//   @include flex-wrap(wrap);
//   @include justify-content(flex-start);
//   @include align-content(flex-start);

//   border-bottom: 1px solid #5db9d1;
//   border-top: 1px solid #5db9d1;

//   height: 45px;
//   margin: 3px auto;
//   width: 1000px;

//   .main-nav-button {

//     line-height: 45px;
//     margin-right: 10px;
//     padding: 0 5px;

//     &:nth-of-type(1) {
//       width: 150px;
//     }

//     &:nth-of-type(2),
//     &:nth-of-type(3) {
//       margin-right: 13px;
//       width: 110px;
//     }

//     &.active {
//       border-bottom: 1px solid #0d596d;
//       border-top: 1px solid #0d596d;
//     }

//     a {
//       color: $black;
//       display: block;

//       &:nth-of-type(1) {
//         margin-left: 10px;
//       }

//       &:hover,
//       &.active {
//         color: #5db9d1;
//         cursor: pointer;
//         font-size: 16px;
//         font-weight: 700;
//         text-decoration: none;
//       }
//     }
//   }
// }
