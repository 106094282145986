#defined-contribution {
  color: #333;
  font-size: 12px;
  margin-top: 10px;
  background-color: #f2f3f4;

  h3 {
    font-size: 16px;
    text-transform: none;
    margin-top: 5px;
    span {
      margin-right: 5px;
    }
  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .buckets {
    background-color: #fff;
  }
  &.bundle {
    background: none;
    .header h3 {
      font-size: 20px;
    }
    .buckets {
      margin-bottom: 5px;
    }
    .bucket {
      background-color: #fff;
    }
  }
  .foldable {
    padding: 10px 20px;
  }
  .bucket {
    padding: 10px 20px;
    border: 2px solid #f2f3f4;
    &.fixed-height {
      min-height: 250px;
    }
    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    h3 {
      color: $maxwell-dark-blue;
    }
  }
  i {
    font-size: 20px;
    color: $maxwell-dark-blue;
  }
  #rolloverTooltip {
    display: inline;
    padding-top: 4px;
  }
}
