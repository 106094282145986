.header h2 {
  padding: 15px;
  background-color: $shopping-darkest-blue;
  color: white;
  font-size: 20px;
  font-weight: 100;
  font-style: italic;
  text-transform: uppercase;
}

.yourOptions {
  & tbody > tr {
    border-top: 1px solid #999;
  }
  & thead {
    font-size: 13px;
  }
  & td {
    padding: 10px;
  }
  & th {
    padding: 10px;
  }
}
