/* Used for finer width control for forms */

@use "sass:math";

.grid24 .col-xs-1 {
  width: math.div(1, 24) * 100%;
}
.grid24 .col-xs-2 {
  width: math.div(2, 24) * 100%;
}
.grid24 .col-xs-3 {
  width: math.div(3, 24) * 100%;
}
.grid24 .col-xs-4 {
  width: math.div(4, 24) * 100%;
}
.grid24 .col-xs-5 {
  width: math.div(5, 24) * 100%;
}
.grid24 .col-xs-6 {
  width: math.div(6, 24) * 100%;
}
.grid24 .col-xs-7 {
  width: math.div(7, 24) * 100%;
}
.grid24 .col-xs-8 {
  width: math.div(8, 24) * 100%;
}
.grid24 .col-xs-9 {
  width: math.div(9, 24) * 100%;
}
.grid24 .col-xs-10 {
  width: math.div(10, 24) * 100%;
}
.grid24 .col-xs-11 {
  width: math.div(11, 24) * 100%;
}
.grid24 .col-xs-12 {
  width: math.div(12, 24) * 100%;
}
.grid24 .col-xs-13 {
  width: math.div(13, 24) * 100%;
}
.grid24 .col-xs-14 {
  width: math.div(14, 24) * 100%;
}
.grid24 .col-xs-15 {
  width: math.div(15, 24) * 100%;
}
.grid24 .col-xs-16 {
  width: math.div(16, 24) * 100%;
}
.grid24 .col-xs-17 {
  width: math.div(17, 24) * 100%;
}
.grid24 .col-xs-18 {
  width: math.div(18, 24) * 100%;
}
.grid24 .col-xs-19 {
  width: math.div(19, 24) * 100%;
}
.grid24 .col-xs-20 {
  width: math.div(20, 24) * 100%;
}
.grid24 .col-xs-21 {
  width: math.div(21, 24) * 100%;
}
.grid24 .col-xs-22 {
  width: math.div(22, 24) * 100%;
}
.grid24 .col-xs-23 {
  width: math.div(23, 24) * 100%;
}
.grid24 .col-xs-24 {
  width: math.div(24, 24) * 100%;
}
