.arrows {
  color: $shopping-background;
  margin-left: 140px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: default;
  outline: none;
  a {
    text-decoration: none;
    color: $shopping-soft-gray;
    text-transform: uppercase;
  }
  a:hover {
    text-decoration: none;
    outline: none;
  }
  button,
  li {
    background: $shopping-light-gray;
    border: 0;
    color: $shopping-soft-gray;
    text-transform: uppercase;
    font-weight: bold;
    cursor: default;
    position: relative;
    padding: 0px 50px 0px 15px;
    width: 230px;
    height: 44px;
    vertical-align: top;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 18px;
    padding-left: 50px;
    cursor: pointer;
    &:before {
      content: '';
      border-top: 22px $shopping-light-gray solid;
      border-bottom: 22px $shopping-light-gray solid;
      border-left: 10px #f7fdff solid;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      content: '';
      border-top: 22px $shopping-background solid;
      border-bottom: 22px $shopping-background solid;
      border-left: 10px $shopping-light-gray solid;
      position: absolute;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .current {
    background: $shopping-light-blue-background;
    color: $shopping-light-blue-text;
    a {
      color: $shopping-light-blue-text;
    }
    &:before {
      border-top: 22px $shopping-light-blue-background solid;
      border-bottom: 22px $shopping-light-blue-background solid;
    }
    &:after {
      border-left: 10px solid $shopping-light-blue-background;
    }
  }

  .completed {
    background: $shopping-soft-blue-background;
    color: $shopping-soft-blue-text;
    a {
      color: $shopping-soft-blue-text;
    }
    &:before {
      border-top: 22px $shopping-soft-blue-background solid;
      border-bottom: 22px $shopping-soft-blue-background solid;
    }
    &:after {
      border-left: 10px solid $shopping-soft-blue-background;
    }
  }

  .first:before {
    border-top: 0;
    border-bottom: 0;
  }

  .last:after {
    border-top: 0;
    border-bottom: 0;
  }
}

.progress-arrows {
  color: $shopping-background;

  a {
    text-decoration: none;
    color: $shopping-soft-gray;
  }

  a:hover {
    text-decoration: none;
  }

  div {
    background-color: $shopping-light-gray;
    border: 2px solid transparent;
    display: block;
    font-size: 18px;
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    position: relative;
    margin-right: 22px;
    margin-bottom: 10px;
    width: 310px;

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: '';
      border-top: 2px solid transparent;
      border-right: 2px solid transparent;
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0px;
      top: 8px;
      z-index: 150;
    }

    &.active-error {
      border-color: $maxwell-red;

      &.current:after {
        border-color: $maxwell-red;
      }
    }

    &.current {
      background: $shopping-light-blue-background;
      color: #63b9cf;

      a {
        color: $shopping-light-blue-text;
      }

      &:after {
        background: $shopping-light-blue-background;

        -moz-transform: translate(10px, 4px) rotate(45deg);
        -webkit-transform: translate(10px, 4px) rotate(45deg);
        -o-transform: translate(10px, 4px) rotate(20deg);
        -ms-transform: translate(10px, 4px) rotate(45deg);
        transform: translate(10px, 4px) rotate(45deg);
      }
    }

    strong {
      margin-right: 20px;
    }

    &.completed {
      background: $shopping-soft-blue-background;

      &.current {
        background: $shopping-light-blue-background;
      }

      a,
      i {
        color: $shopping-soft-blue-text;
      }

      i {
        margin-left: -10px;
        margin-right: 5px;
      }
    }
  }
}

.welcome-arrows {
  .before {
    width: 0px;
    height: 0px;
    border-width: 24px 0px 20px 10px;
    margin-top: 10px;
    border-style: solid;
    border-color: #e4e4e4 #e4e4e4 #e4e4e4 transparent;
    float: left;
  }

  .after {
    width: 0px;
    height: 0px;
    border-width: 22px 0px 20px 10px;
    margin-top: 10px;
    border-style: solid;
    border-color: transparent transparent transparent #e4e4e4;
    float: left;
  }
  a {
    text-decoration: none;
    color: #8b8b8b;
    text-transform: uppercase;
    cursor: default;
  }
  button {
    float: left;
    background: #e4e4e4;
    border: 0;
    color: #8b8b8b;
    text-transform: uppercase;
    font-weight: bold;
    cursor: default;
    position: relative;
    padding: 0px 50px 0px 15px;
    width: 230px;
    height: 44px;
    vertical-align: top;
    margin-bottom: 35px;
    margin-top: 10px;
    font-size: 18px;
    padding-left: 50px;
    cursor: pointer;
  }
  .completed {
    background: $shopping-soft-blue-background;
    color: $shopping-soft-blue-text;
  }
  .current {
    background: $shopping-light-blue-background;
    color: $shopping-light-blue-text;
    & > a {
      color: $shopping-light-blue-text;
    }
    .before {
    }
    &.after {
      background: transparent !important;
      border-color: transparent transparent transparent
        $shopping-light-blue-background;
    }
  }
}
