// Footer
// right now this is on the member side
// https://css-tricks.com/snippets/css/a-guide-to-flexbox/

$grey: #f4f4f7;
$white: rgba(255, 255, 255, 1);
$secondBlue: #0d596d;

.footer-section {
  bottom: 0;
  margin-top: 10px;
  position: absolute;
  width: 100%;

  footer {
    background-color: $grey;
    height: 95px;

    &.broker-footer {
      border-top: 1px solid $secondBlue;
    }

    &.maxwell-footer {
      background-color: $secondBlue;
      color: $white;
      font-family: Lato, Helvetica, Arial, sans-serif;
      font-size: 12px;
      height: 40px;
    }

    .flex-container {
      height: 95px;
      margin: 0;
      padding: 0;
      list-style: none;

      @extend %flexbox;

      @include flex-flow(row wrap);
      @include justify-content(center);
      @include align-content(center);
      @include align-items(center);

      width: 100%;
    }

    .flex-item {
      height: 95px;
      line-height: 95px;
      margin: 0 45px;

      width: 200px;
      @include align-self(center);

      img {
        max-height: 80px;
        max-width: 175px;
        padding: 5px 0;
      }
    }

    .footer-inner {
      height: 100%;
      margin: 0 auto;
      width: 1000px;
    }

    .footer-links {
      display: inline-block;
      height: 100%;
      width: 69%;

      a {
        color: $white;
        line-height: 40px;
        margin-right: 20px;
        text-transform: capitalize;

        &:hover {
          color: #7fcee1;
          text-decoration: none;
        }
      }
    }

    .footer-copyright {
      display: inline-block;
      height: 100%;
      line-height: 40px;
      text-align: right;
      width: 30%;
    }
  }
}
