// Decision Support
//
#decission-support {
  #partnerYearlyIncome {
    width: 96%;
  }
  .input-group {
    padding-left: 15px;
  }
  .blue-divider {
    height: 1px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: $maxwell-blue;
    width: 100%;
  }
  .radio-wrapper {
    padding-left: 30px;
  }
  .radio-wrapper .radio-inline {
    width: 32%;
    margin-left: auto;
    margin-right: auto;
  }
  .radio-margin-bottom {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  .text-group .new-line {
    display: block;
    padding-top: 0px;
  }
  #homeOwnershipWrapper {
    display: -webkit-box;
    label {
      padding-left: 10px;
    }
  }
  #homeOwnershipMonthlyAmount {
    height: 32px;
  }
  .dependent {
    margin: 5px;
    .checkbox-inline {
      margin-left: 15px;
      margin-right: 5px;
    }
    span {
      margin-left: 5px;
    }
  }
  #recommendationToggle {
    text-align: center;
  }
}

#decisionPrevious {
  margin: 0px;
}
#decisionComplete {
  margin: 0px;
}

// Modal Styles

#decission-support-custom-modal {
  font-family: Lato, Helvetica, Arial, sans-serif;
  h3 {
    font-style: italic;
  }
  .top-description {
    margin-bottom: 25px;
  }

  .modal-dialog {
    width: 700px;
  }

  .modal-content {
    border-radius: 0;
    background-color: $shopping-background;
  }

  .modal-header {
    border-bottom: 0px;
    padding: 2px 30px;
    .close {
      margin-top: 9px;
      margin-right: -16px;
      opacity: 1 !important;
      span {
        color: #80c8da;
        font-size: 50px;
        font-weight: 100;
        height: 42px;
        width: 34px;
      }
    }
  }

  .modal-body {
    padding-top: 0;
    padding-left: 30px;
    padding-right: 20px;

    .no-left-padding {
      padding-left: 0;
    }

    .padding-right-add {
      padding-right: 30px;
    }

    .input-group {
      padding-left: 0px;
    }

    .margin-left-shrink {
      margin-left: -25px;
    }

    .padding-align {
      padding-left: 2px;
    }

    .kidcollege-padding-title {
      padding-top: 7px;
    }

    .kidcollege-padding-radio {
      padding-left: 35px;
      padding-top: 11px;
    }
    .kidcollege-padding-label {
      margin-left: -15px;
      padding-left: 0;
    }

    .error {
      color: rgba(255, 0, 0, 1);
    }

    .error-hide {
      display: none;
    }

    .error-label {
      padding-left: 0;
    }

    .error-testbox {
      border: 1px solid rgba(255, 0, 0, 1);
    }

    #decission-support {
      // background: green;
    }

    #homeOwnership {
      .radio-inline {
        height: 50px;
      }

      .radio-second {
        width: 106px;
      }

      .radio-third {
        width: 116px;
      }
    }
    .input-group .control-label {
      padding-right: 0px;
    }
    #homeOwnershipMonthlyAmount {
      &.form-control {
        width: 74%;
      }
      height: 35px;
    }

    .blue-line {
      background: #5db9d1;
      height: 1px;
      margin: 10px 0;
      width: 98%;
    }

    .control-label {
      span {
        display: block;
      }
    }

    .no-bold-label {
      font-weight: normal;
    }
  }

  #errorResponse {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
  }

  .modal-footer {
    padding: 0;
    margin-top: 0;
  }

  .text-left {
    text-align: left;
  }

  .bottom-text {
    margin-right: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;

    .info-icon {
      float: left;
      height: 40px;
      margin-right: 10px;
      width: 40px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .modal-buttons {
    margin-bottom: 1px;
    margin-left: -3px;
    margin-top: -1px;
    padding: 10px;
    width: 50%;

    &.no-button {
      @extend .shopping-button;
      color: white !important;
      border-color: white !important;
      background-color: #999999 !important;
      border-radius: 0;

      &:hover {
        background-color: #8b8b8b !important;
      }
    }

    &.yes-button {
      background-color: #083c4a;
      @extend .shopping-button;
      border: 2px solid #58acc1;
      border-radius: 0;
      color: white;
    }
  }
}

body.modal-open {
  overflow: hidden;
}

#iframe-modal {
  .modal-dialog {
    width: 1000px;
    .modal-header {
      display: none;
    }
    .modal-body {
      padding: 0px;
    }
  }
  #iframeContainer {
    position: relative;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 2000;
    #mapMyBenefitsIframe {
      width: 100%;
      height: 970px;
      border: 1px;
      background-color: white;
    }
  }
}

#broker-recommendation {
  padding-top: 10px;
  padding-bottom: 10px;
}

// import modal
#getrecommendations-import-error {
  color: red;
  text-align: center;
}

// Deciscion SUpport big button
.dsmmblink {
  background: #06417c;
  border: 1px solid #06417c;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  float: right;
  height: 140px;
  padding: 20px;

  .dsmmblink-button {
    background: transparent;
    border: none;
    color: #003f7e;
    outline: none;
    padding: 0;
    text-align: left;
    text-decoration: none;

    .title {
      width: 100%;
    }

    .info {
      margin-left: 130px;
    }
  }

  h3 {
    color: #fff;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 4px;
    margin-left: 130px;
    margin-top: 12px;
    text-transform: none;
  }

  p {
    color: #fff;
    line-height: 17px;
    margin-top: 0px;
    padding-right: 10px;
    z-index: 2;
  }

  .svgwrap {
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 0;

    svg {
      height: 95px;
      width: 110px;

      image {
        height: 95px;
        width: 110px;
      }
    }
  }
}
