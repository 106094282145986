.confirmed-tile-action-marker {
  font-family: 'Font Awesome 5 Free';
  content: '\f06a';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 55px 55px 0;
  border-color: transparent $maxwell-orange transparent transparent;
  position: absolute;
  right: 0px;
  top: 0px;
}
.confirmed-tile-action-marker:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f06a';
  color: white;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  right: -45px;
  top: 5px;
}

.action-tile-header {
  h3 {
    padding-left: 15px;
  }
}

.action-tile-instructions {
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  background-color: $shopping-checkout-blue-dark;
  margin-bottom: 15px;
}

.action-tile {
  background-color: $shopping-checkout-blue-dark;
  position: relative;
  min-height: 120px;
  margin-bottom: 10px;
  border: 1px solid $maxwell-blue;
  color: $maxwell-dark-blue;

  .icon {
    position: absolute;
    display: inline-block;
    top: 15px;
    left: 20px;
    font-size: 22px;
    color: $maxwell-orange;
  }

  .info {
    display: inline-block;
    margin-left: 48px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 430px;

    h4 {
      text-transform: none;
    }
  }

  .buttons {
    display: inline-block;
    position: absolute;
    right: 17px;
    width: 140px;

    .shopping-button {
      margin-bottom: 5px;
      width: 100%;
      cursor: pointer;
    }

    .disabled {
      pointer-events: none;
      cursor: default;
    }

    .mark {
      padding: 5px;
      text-transform: none;
      font-size: 13px;
      background-color: rgba(0, 0, 0, 0);
      border-color: $maxwell-blue;
      color: $maxwell-blue;
      font-family: Lato, Helvetica, Arial, sans-serif;
      height: 35px;
    }

    .complete {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 45px;
      margin-bottom: 0px;
      white-space: nowrap;
    }
  }

  .apply-opacity {
    opacity: 0.5;
  }
}
