// So some things are interited from the nav bar right now because its eaiser to place the div elem in the nav bar
#my-cart-overlay {
  left: -331px;
  position: relative;

  &.display-none {
    height: 0;
    width: 0;
    display: none;

    .my-cart-overlay-wrapper {
      height: 0;
      width: 0;
    }
  }

  .arrow-up {
    left: 405px;
  }
}

.my-cart-overlay-wrapper {
  height: 530px;
  width: 490px;
  opacity: 0;
  transition: opacity 0.5s ease-in;

  &.enter-animation {
    height: auto;
    opacity: 1;
  }

  &.exit-animation {
    opacity: 0;
  }
}

#my-cart-overlay-content {
  background-color: $white;
  border-top: 5px solid #0d596d;
  position: relative;
  top: -5px;

  .row {
    margin: 0;
  }

  .my-cart-overlay-content-inner {
    border-bottom: 1px solid #c7cbd6;
    border-left: 1px solid #c7cbd6;
    border-right: 1px solid #c7cbd6;
    height: 100%;
    padding: 15px;
    width: 100%;
  }
}

.my-cart-overlay-header {
  @extend %flexbox;
  @include flex-wrap(wrap);
  @include flex-direction(row);
  height: 80px;
  width: 100%;

  .flexitem {
    @include flex(1);
    width: 50%;
  }

  .my-cart-totalproducts {
    color: #5db9d1;
    font-size: 20px;
    font-weight: 400;
    padding-left: 10px;
    text-transform: none;
  }
}

.my-cart-review-button {
  color: $white;
  font-size: 15px;
  height: 42px;
  line-height: 25px;
  width: 230px;
}

.my-cart-overlay-inner {
  @extend %flexbox;
  @include flex-wrap(wrap);
  @include flex-direction(row);
  @include justify-content(flex-start);
  @include align-content(flex-start);
  @include align-items(stretch);

  height: 340px;
  overflow-y: auto;
  width: 100%;

  .my-cart-overlay-row {
    @extend %flexbox;

    height: 45px;
    line-height: 36px;
    padding: 5px 10px;
    text-transform: none;
    width: 458px;

    &:nth-child(odd) {
      background-color: rgba(227, 243, 246, 0.3);
      border-bottom: 1px solid #c7cbd6;
      border-top: 1px solid #c7cbd6;
    }

    &:last-of-type {
      border-bottom: 1px solid #c7cbd6;
    }

    .flexitem {
      @include flex(1);
      @include flex-grow(2);
      height: 36px;

      &:nth-of-type(2) {
        @include flex-grow(1);
        text-align: right;

        span:nth-of-type(1) {
          text-transform: capitalize;
        }
      }
    }

    .my-cart-productcost {
      span:nth-of-type(1) {
        font-style: italic;
        font-weight: 400;
      }
    }
  }
}

.my-cart-overlay-footer {
  @extend %flexbox;
  @include flex-wrap(wrap);
  @include flex-direction(row);

  @include justify-content(center);
  @include align-content(center);
  @include align-items(center);

  height: 80px;
  padding-top: 16px;
  width: 100%;

  .flexitem {
    @extend %flexbox;
    @include flex(1);
    @include justify-content(center);
    @include flex-direction(column);

    border-right: 1px solid #c7cbd6;
    height: 100%;
    line-height: 80px;
    width: 163px;

    &:nth-of-type(3) {
      border-right: 0;
    }
  }

  span {
    color: #5db9d1;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    text-transform: none;

    &:nth-of-type(1) {
      font-weight: 400;
      margin-bottom: 10px;
    }

    &:nth-of-type(2) {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
