// should this be deprecated?
.shopping-modal {
  .modal-body {
    padding: 10px 20px;
  }
  .modal-header {
    background-color: #f2f3f4;
    padding: 10px 20px 5px;
    border-bottom: 1px solid #ccc;
    h3 {
      color: #333;
      margin-top: 7px;
    }
    span {
      font-weight: 300;
      font-size: 40px;
    }
  }
}

#confirmModal {
  font-family: Lato, Helvetica, Arial, sans-serif;

  .modal-header {
    border-bottom: 0px;
    padding: 2px 30px;
    .close {
      margin-top: 10px;
      margin-right: -16px;
      opacity: 1 !important;
    }
  }
  .modal-content {
    border-radius: 0px;
    background-color: $shopping-background;
  }
  .modal-body {
    padding: 10px 30px;
  }

  #confirmModalBody h3 {
    margin-top: 0px;
  }
  h3 {
    font-style: italic;
  }
  .modal-buttons {
    margin-left: -3px;
    width: 50%;
  }
  #confirmModalNoLabel {
    @extend .shopping-button;
  }
  #confirmModalYesLabel {
    @extend .shopping-button;
  }
  .modal-footer {
    border-top: 0px;
    padding: 0px;
    margin-top: 15px;
  }
}
.pending-approved {
  margin-top: 15px;
  line-height: 1.7;
  font-size: 12px;
}

// Modal used for disclaimers
.disclaimer-modal {
  .progress-bar {
    line-height: 14px;
  }
  .modal-content {
    border-radius: 0px;
    background-color: $shopping-background;
    h5 {
      font-size: 16px;
      font-style: italic;
      padding-bottom: 5px;
    }

    .modal-body {
      padding-top: 0px;
    }
  }

  .modal-header {
    border-bottom: 0px;
    padding: 2px 20px;
    .close {
      margin-top: 10px;
      margin-right: -16px;
      opacity: 1 !important;
      background-image: url('/images//site_wide.sprite.png');
      height: 42px;
      background-position: -200px 0px;
      width: 34px;
      background-size: 300px;
      font-size: 0px;
    }
  }
  h3 {
    font-style: italic;
    padding-top: 0px;
  }
  .error {
    color: $maxwell-red;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 300;
  }
  .modal-buttons {
    margin-left: -3px;
    width: 50%;
  }

  .overflow {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px 0px 15px;
    height: 400px;
    overflow-y: scroll;
  }
}

// Default styling for member modals (old?)
.maxwell-modal {
  font-family: Lato, Helvetica, Arial, sans-serif;

  .maxwell-modal-header {
    border-bottom: 0px;
    padding: 2px 30px;
    .close {
      margin-top: 10px;
      margin-right: -16px;
      opacity: 1 !important;
    }
  }
  .maxwell-modal-content {
    border-radius: 0px;
    background-color: $shopping-background;
  }
  .maxwell-modal-body {
    padding: 10px 30px;
    h3 {
      margin-top: 0px;
    }
  }
  h3 {
    font-style: italic;
  }
  .maxwell-modal-footer {
    border-top: 0px;
    padding: 0px;
    margin-top: 15px;
  }
}

#modalSpinner {
  width: 300px;
  height: 46px;
  bottom: 0px;
  left: 300px;
}

.coverageLevelForm {
  .select2-container {
    margin-left: -14px;
    width: 115%;
  }
}

.qle-confirm-wrap-member {
  padding-top: 5px;
  text-align: center;
}

// qle modal
.member-qle-fake-footer {
  bottom: 0;
  display: block;
  padding: 0;
  position: absolute;

  button {
    background-color: #083c4a;
    border: 2px solid #58acc1;
    color: #58acc1;
    display: inline-block;
    font-weight: 400;

    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    width: 50%;

    &:nth-of-type(2) {
      margin-left: -4px;
    }
  }
}

#bth-member-qle-yesno {
  margin-left: 15px;

  .btn-primary {
    border-color: $maxwell-blue;
  }

  label {
    background: rgba(255, 255, 255, 1);
    color: $shopping-soft-gray;

    &.active {
      background: $maxwell-blue;
      color: rgba(255, 255, 255, 1);
    }
  }

  input {
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    position: absolute;
  }
}

.qle-form-text {
  margin: 0 auto;
  display: block;
  padding-left: 4px;
  margin-bottom: 50px;
}

// NEW styling for member-facing modals in accordance with style guide
// initially implemented for Conditional Questions

.maxwell-modal-v2 {
  .modal-content {
    border-radius: 0px;
    background-color: $shopping-background;
  }

  .modal-body {
    padding: 0px;
  }

  // put padding just on the stuff within the container
  // this separates out the bottom buttons (which are technically part of modal-body)
  .modal-container {
    padding: 10px 30px;
  }

  .modal-header {
    padding: 2px 30px;
    // formatting for the X icon
    .close {
      margin-top: 10px;
      margin-right: -16px;
      opacity: 1 !important;
      background-image: url('/images//site_wide.sprite.png');
      height: 42px;
      background-position: -200px 0px;
      width: 34px;
      background-size: 300px;
      font-size: 0px;
    }
  }
  // this is used for the modal header
  h3 {
    font-style: italic;
    padding-top: 0px;
  }

  .progress {
    margin-top: 20px;
  }
  .progress-bar {
    line-height: 14px;
  }

  // formatting specifically for cond'l questions
  .question-row {
    margin-top: 15px;
  }
  .bigNumber {
    margin-top: 4px;
  }
  .free-text-response {
    margin: 25px 0px 15px 15px;
    padding: 5px;
  }

  .user-response-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  // errors and warnings
  .error {
    color: $maxwell-red;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 300;
  }

  .warning-help-text {
    padding-top: 10px;
    font-weight: 500;
  }

  // footer buttons must be separate from modal-container
  .footer-buttons {
    margin-left: 1px;
    display: inline;
    button {
      margin-left: -1px;
      // this is terrible and gross and i'm sorry
      width: 49.8%;
    }
  }
}

// Styling for member-facing confirm-box modals in accordance with style guide

.maxwell-confirm-box {
  background-color: $shopping-background;
  .modal-body {
    padding: 10px 30px;
  }
  .modal-footer {
    .btn {
      margin-left: 0px;
    }
    padding: 0px;
  }
  .modal-buttons {
    border-radius: 0;
    text-transform: uppercase;
    // this is terrible and gross and i'm sorry
    width: 49.7%;
  }
  .yes-button {
    @extend .shopping-button-info;
    color: #58acc1;
    background-color: #083c4a;
    border: 2px solid #58acc1;
  }
  .no-button {
    @extend .shopping-button-default;
    background-color: $shopping-button-gray-background;
  }
}

.maxwell-alert-box {
  .modal-buttons {
    width: 100%;
  }
}

// Styling for updated Get Help Modal - MAXT-13436.

.need-help-icon {
  padding-top: 10px;

  .need-help-icon-image {
    width: 75px;
    height: auto;
  }
}

.help-center-title {
  color: #7fcee1;
  text-transform: none;
}

.help-center-list {
  padding: 5px !important;
}

.form-group {
  .control-label {
    text-align: left;
  }
  .technical-question {
    margin-bottom: 0px;
  }
  .generate-code-div {
    text-align: center;
  }
}

#needHelpDescription {
  display: none;
}

#access-code {
  font-weight: bold;
}
