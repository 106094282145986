.shopping-button {
  text-transform: uppercase;
  border: 2px solid $shopping-button-dark-blue-text;
  color: $shopping-button-dark-blue-text;
  background-color: $shopping-button-dark-blue-background;
  padding: 10px;
  font-weight: 400;
  display: inline-block;
  text-align: center;

  &:hover {
    color: white;
    border-color: white;
    background-color: $shopping-button-primary-blue-background;
    text-decoration: none;
  }
}
.shopping-button-primary {
  @extend .shopping-button;
  color: white;
  border-color: white;
  background-color: $shopping-button-green-background;
  &:hover {
    background-color: $maxwell-soft-green;
  }
}

.remove-from-cart:hover {
  background-color: $maxwell-red !important;
}

.shopping-button-info {
  @extend .shopping-button;
  color: white;
  border-color: white;
  background-color: $shopping-button-primary-blue-background;
  &:hover {
    background-color: $maxwell-soft-blue;
  }
}

.shopping-button-default {
  @extend .shopping-button;
  color: white !important;
  border-color: white !important;
  background-color: $shopping-button-gray-background !important;
  &:hover {
    background-color: $shopping-soft-gray !important;
  }
}
.shopping-button-fixed-size {
  @extend .shopping-button;
  display: inline-block;
  width: 200px;
}
.shopping-button-danger {
  @extend .shopping-button;
  color: white !important;
  border-color: white !important;
  background-color: $maxwell-red !important;
  &:hover {
    background-color: $maxwell-soft-red !important;
  }
}

.shopping-button-large {
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
}
.shopping-button-small {
  padding: 7px;
  font-size: 12px;
  font-weight: 400;
}
.info-tooltip {
  font-size: 1.8em;
  color: $maxwell-blue;
  margin-top: 5px;
}

.muted-button-primary {
  background-color: $shopping-doctor-button-background !important;
  color: $shopping-doctor-button-text !important;
}
