//maxwell dark blue
$maxwell-dark-blue: #0d596d;
$maxwell-darker-blue: #083c4a;
$maxwell-darkest-blue: #06303b;
//maxwell red
$maxwell-red: #ad3b4a;
$maxwell-soft-red: #d57f8e;
$maxwell-light-red: #f1dee0;
//maxwell orange
$maxwell-orange: #de7f38;
$maxwell-soft-orange: #f2a976;
$maxwell-light-orange: #f8e7dd;
//maxwell yellow
$maxwell-yellow: #fbdf53;
$maxwell-soft-yellow: #fbd787;
$maxwell-light-yellow: #fff3e1;
//maxwell red
$maxwell-green: #83a243;
$maxwell-soft-green: #b3c375;
$maxwell-light-green: #eaf2df;
//maxwell blue
$maxwell-blue: #5db9d1;
$maxwell-soft-blue: #7fcee1;
$maxwell-light-blue: #e3f3f6;
//maxwell purple
$maxwell-purple: #915682;
$maxwell-soft-purple: #bb9cad;
$maxwell-light-purple: #ebe0ef;
//maxwell grey
$maxwell-grey: #bbb;
$maxwell-light-grey: #f1f2f7;

$mh-dark-text: #474747;
$mh-light-text: #818181;
$mh-lighter-text: #767676;

// these shopping colors are fucking out of control

$shopping-soft-gray: #8b8b8b;
$shopping-light-gray: #e4e4e4;
$shopping-background: #f7fdff;

$shopping-light-blue-text: #63b9cf;
$shopping-light-blue-background: #dff1f5;
$shopping-light-gray-blue: #e8f0f2;

$shopping-soft-gray-blue: #d9e7ea;
$shopping-soft-gray-bluer: #d3e8ec;
$shopping-checkout-blue-light: #edf6f8;
$shopping-checkout-blue-dark: #dceaed;

$shopping-soft-blue-text: #a5d8e4;
$shopping-soft-blue-background: #eaf7fa;

$shopping-darkest-blue: #0b2e39;
$shopping-dark-blue: #1b586d;

$shopping-button-dark-blue-text: #58acc1;
$shopping-button-dark-blue-background: #083c4a;
$shopping-button-primary-blue-background: #58acc1;

$shopping-button-green-background: #83a243;
$shopping-button-gray-background: #999999;

$shopping-doctor-button-background: #dce6f5;
$shopping-doctor-button-text: #739cdb;
$shopping-footer-background: #fff;
$shopping-footer-text: #a3a2a1;

// #0B3C49
$white: rgba(255, 255, 255, 1);
$red: rgba(255, 0, 0, 1);
$black: rgba(0, 0, 0, 1);

$ghost: #c7cad5;
$nevada-gray: #5a6466;
$gray: #929292;
$gray-whisper: #e9eaf3;
$gray-alto: #cfcfcf;

// Memberchange status related colors
$red-damask: #df7f39;
$texas-rose: #febf52;
$sushi: #83a242;

$gold-drop-orange: #ea8200;

// integrations
$integrations-border-gray: rgba(#979797, 0.44);
$integrations-background-gray: rgba(#e1e3e9, 0.44);
$integrations-remove-red: #d0021b;
