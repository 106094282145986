.rememberme-checkbox {
  position: relative;
  top: -14px;
  left: 6px;
  height: 25px !important;
}

.rememberme-group {
  position: relative;
  top: 5px;
}

.rememberme-label {
  position: relative;
  top: -8px;
  font-weight: normal;
}
