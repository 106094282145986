.review {
  background-color: $shopping-checkout-blue-light;
  padding: 5px 15px;
  float: left;
  width: 100%;
  .row {
    margin-left: 0px;
  }
  .review-left-container {
    float: left;
    width: 50%;
  }
  .product-name-column {
    width: 310px;
    ul {
      padding-left: 20px;
    }
  }
  .vendor-logo {
    width: 13%;
  }
  .effective-date {
    margin-top: 6px;
    width: 21%;
  }
  .your-cost {
    margin-top: 6px;
    .cost {
      width: 55px;
    }
    .frequency {
      margin-left: 0px;
    }
  }
  .product-coverage-tier {
    list-style: none;
    padding: 5px 10px !important;
  }
  .review-right-container {
    float: right;
    text-align: right;
    margin-top: 6px;
    &.edit-cart {
      margin-top: 25px !important;
    }
  }

  hr {
    margin: 10px 0px;
    border-top: 1px solid $shopping-soft-gray-blue;
  }
}
div#info-container {
  padding-bottom: 10px;
  float: left;
  width: 100%;
  .dependent {
    margin-bottom: 20px;
  }
}
.review-container-header {
  background-color: darken(#d9e7ea, 5);
  float: left;
  width: 100%;
  margin: 0px;
  h4 {
    font-size: 14px;
    font-weight: 700;
  }
  small {
    font-size: 11px;
  }
  .vendor-logo {
    width: 13%;
  }
  .effective-date {
    margin-bottom: 10px;
    width: 21%;
  }
  .your-cost {
    width: 15%;
  }
  .product-name-column {
    width: 310px;
  }
  .pay-period {
    padding-right: 0px;
  }
}
.review-header {
  background-color: $shopping-checkout-blue-dark;
  padding: 2px 15px;
  text-transform: uppercase;
  float: left;
  width: 100%;
  h4 {
    font-size: 16px;
    color: $shopping-dark-blue;
  }
}

#form-container,
.form-container {
  background-color: $shopping-checkout-blue-dark;
  padding: 5px 15px;
  .alert.alert-info.no-margin {
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    background: #dceaed;
    border: #dceaed;
  }

  td {
    padding: 8px 7px;
  }
}

#checkoutForm {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  .form-group {
    margin-bottom: 7px;
  }
  .spinner {
    height: 44px;
    width: 180px;
  }
  br {
    line-height: 40px;
  }
}
#checkoutFormAlert {
  margin-left: 0px;
  padding: 0px;
}
.beneficiariesButton {
  margin-top: 20px;
}
.has-error .form-control {
  border: '2px solid #ccc';
  border-color: $maxwell-red;
}
.confirm-product {
  margin-right: 14px;
}
.product-confirmed {
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 14px;
  color: $maxwell-darker-blue;
  em {
    text-transform: uppercase;
    font-weight: bold;
  }
}

#ssnRequired {
  .form-group.has-error {
    border: none;
    color: #ad3b4a;
  }
}

.pre-header-message {
  padding: 8px 15px;
  h2 {
    font-weight: 300;
    font-style: italic;
  }
}
