.products-by-type-header {
  padding: 10px 20px;
  background-color: $maxwell-dark-blue;
  margin-bottom: 8px;
  h3 {
    margin: 0px;
    font-size: 18px;
    color: white;
  }
  .col-sm-7.by-type-right {
    text-align: right;
  }
  .waive-all {
    cursor: pointer;
  }
}

#products-view-container .header {
  background-color: $maxwell-darkest-blue;
  padding: 13px 20px;
  margin-top: 2px;
  margin-bottom: 6px;
  h2 {
    padding: 0px;
    margin: 0px;
    margin-top: 3px;
    display: inline-block;
  }
  .waiveAll {
    display: inline-block;
    float: right;
    top: 3px;
  }
}

#products-container .products {
  display: flex;
  flex-wrap: wrap;
  .tile-wrapper:nth-child(even) .tile {
    margin-right: 0px;
  }
}

#products-container.comparison-container .products {
  margin-top: 20px;
}

#products-container .tile {
  color: $maxwell-dark-blue;
  background-color: $maxwell-light-blue;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 14px;
  position: relative;
  &:hover {
    -webkit-box-shadow: inset 0px 0px 0px 1px $maxwell-blue;
    -moz-box-shadow: inset 0px 0px 0px 1px $maxwell-blue;
    box-shadow: inset 0px 0px 0px 1px $maxwell-blue;
    cursor: pointer;
  }
  header {
    .plan-type {
      display: inline-block;
      width: 50%;
      text-align: left;
      strong {
        text-transform: uppercase;
      }
    }
    .compare-toggle-container {
      display: inline-block;
      width: 50%;
      text-align: right;
      color: $maxwell-blue;
      a:hover {
        color: $maxwell-blue;
      }
      span {
        color: #aaa;
        cursor: text;
      }
      label {
        font-weight: normal;
      }
      &:hover {
        label {
          text-decoration: underline;
        }
      }
      input {
        margin-left: 8px;
        position: relative;
        top: -2px;
      }
    }
  }
  .tile-body {
    margin-top: 13px;
  }
  .tile-title {
    height: 115px;
    border-top: 1px solid #5db9d1;
    border-bottom: 1px solid #5db9d1;
    margin-bottom: 21px;
  }
  .tile-image-cont {
    height: 60px;
    > * {
      vertical-align: middle;
    }
    span {
      height: 100%;
      display: inline-block;
    }
  }
  .tile-cost {
    margin-bottom: 34px;
    .tile-cost-details {
      font-size: 30px;
    }
    .tile-payment-mayvary {
      position: absolute;
      width: 285px;
      margin: 0px;
    }
  }
  .tile-covered {
    color: $maxwell-purple;
    .tile-covered-individuals {
      font-weight: bold;
    }
  }

  footer {
    margin-bottom: 20px;
    padding: 0 20px 20px;
    font-weight: 400;
    color: $maxwell-orange;
    margin-bottom: 19px;
    border-bottom: 1px solid $maxwell-blue;
    .tile-footer-enrolled {
      color: $maxwell-blue;
    }
    .tile-footer-confirmed {
      color: $maxwell-green;
    }
    i.fa {
      margin-right: 3px;
    }
    &.empty-footer {
      display: none;
    }
  }

  &.full-image {
    .tile-title {
      margin-top: 25px;
      background: rgba(255, 255, 255, 0.9);
      h5 {
        margin-top: 2px !important;
        font-weight: bold;
      }
    }
    footer {
      background-color: $maxwell-light-blue;
    }
  }

  .panel.panel-default {
    border: none;
    background-color: transparent;
    text-align: left;
    box-shadow: none;
  }
  .panel-heading {
    border-bottom: none;
    padding: 10px;
    background-color: #fff;
    border-radius: 3px;
    .glyphicon {
      font-size: 15px;
      color: black;
    }
  }
  .panel-body {
    padding: 15px 8px;
  }

  td {
    border-bottom: 1px solid $maxwell-blue;
    &:last-child {
      color: black;
    }
    i {
      color: black !important;
    }
    a,
    a:hover {
      color: $maxwell-blue;
    }
  }
  tr:last-child td {
    border-bottom: none;
  }

  .employer-contribution {
    margin-bottom: 20px;
  }

  .allempty {
    display: none !important;
  }
  .keyDetails,
  .yourOptions {
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1px solid $maxwell-blue;
    &.empty {
      border-bottom: 1px solid transparent;
    }
    h3 {
      font-size: 14px;
      text-align: left;
      padding-left: 10px;
    }
    i {
      color: $maxwell-dark-blue;
    }
    .panel-heading {
      min-height: 24px;
      height: 24px;
    }
    .panel-heading,
    .panel-body {
      padding: 15px 0;
    }
    td:first-child {
      text-align: left;
    }
    .panel-heading {
      background-color: transparent;
    }
  }

  footer .panel,
  footer .panel-body,
  .visible-tabs.panel,
  .visible-tabs .panel-body {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .visible-sections-wrapper {
    display: inline-block;
  }

  .visible-tabs {
    .btn {
      font-weight: 400;
      border: 1px solid $maxwell-blue;
      color: white;
      background-color: $maxwell-blue;
      border-radius: 0px;
      margin-bottom: 13px;
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &.btn-primary {
        background-color: white;
        border: 1px solid $maxwell-blue;
        color: $maxwell-blue;
      }
      &.btn-sm {
        padding: 6px 12px;
        font-size: 14px;
      }
    }
  }
}

#products-container:not(.comparison-container) {
  footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    text-align: right;
    padding: 0px 20px;
    height: 21px;
    width: 325px;
    margin-bottom: 0px;
    border-bottom: none;
  }
}

#products-container.comparison-container {
  .tile-wrapper,
  .tile-wrapper:nth-child(even) {
    .tile {
      height: auto;
      margin-right: 12px;
      &:hover {
        box-shadow: none;
        cursor: default;
      }
    }
    &:last-child .tile {
      margin-right: 0px;
    }
  }

  .tile-buy {
    height: 55px;
    margin: 21px 0 0;
    padding-bottom: 21px;
  }

  .btn {
    border: none;
    color: white;
  }
  .tile-remove-from-cart {
    background-color: #c7cbd6;
    height: 34px;
  }
  .tile-add-to-cart {
    background-color: $maxwell-orange;
    height: 34px;
  }
}

.products-coverage-outer {
  position: relative;
  // border-bottom: 1px solid $maxwell-grey;
  .bulk-waivable {
    position: absolute;
    right: 15px;
    top: 20px;
    a.waive-all {
      cursor: pointer;
      color: $maxwell-purple !important;
    }
  }
}

.products-coverage-wrapper {
  // background-color: $maxwell-light-blue;
  .chips {
    padding-left: 0px;
  }
  .chip {
    cursor: pointer;
    padding: 3px 8px;
    border-radius: 3px;
    margin: 5px 8px 5px 0px;
    display: inline-block;
    color: $maxwell-purple;
    border: 1px solid $maxwell-purple;
    font-weight: 300;
    &.active {
      background-color: $maxwell-soft-purple;
      color: white;
    }
  }
}

.products-coverage-wrapper,
.products-tile-view-header {
  padding: 10px 0px;
  h4 {
    font-weight: 300px;
    font-size: 24px;
    font-style: italic;
  }
}

.tile-view-mesages-container {
  margin-bottom: 6px;
  background-color: $maxwell-light-blue;
  padding: 13px 20px;
  i.fa {
    margin-right: 3px;
  }
  .alert {
    font-weight: normal;
    padding: 0;
    color: $maxwell-dark-blue;
    &.alert-info {
      background-color: transparent !important;
    }
  }
}
