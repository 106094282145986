.products-box.blue-border {
  width: 100%;
  padding: 10px;
  margin-top: 2px;
  background-color: #edf6f8;
}
.spinner {
  height: 100%;
  width: 97%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
}
.spinners {
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  margin-left: -20px;
  margin-top: -25px;
}
#products-container {
  .products-box {
    padding: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
    height: 100%;
    width: 100%;
    background-color: $shopping-soft-gray-blue;

    h4 {
      font-weight: 700;
      font-size: 16px;

      text-transform: uppercase;
    }
    h4.if-approved {
      margin-bottom: 0px;
    }
    .pay-period {
      margin-top: 10px;
    }
    .buttons {
      margin-right: 20px;
      margin-top: 5px;
    }

    img {
      width: 100% !important;
    }
  }

  // this is for the closed products
  .addQLE {
    margin-top: 2px;
  }
  .panel {
    background-color: $shopping-soft-gray-blue;
    .panel-title span {
      color: $maxwell-blue;
    }
  }
  .keyDetails {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  & > .header,
  & > .shoppingView > .header {
    .row {
      margin: 0px;
    }
    padding: 5px 10px;
    background-color: $shopping-dark-blue;
    h3 {
      margin-top: 10px;
      color: white;
      font-size: 18px;
      font-weight: 700;
    }
    p {
      margin-left: 15px;
      width: 80%;
      color: white;
    }
    .costs {
      font-size: 20px;
      color: white;
      margin-top: 10px;
    }
  }

  .tile {
    float: left;
    margin-right: 6px;
    margin-top: 6px;
    background-color: $shopping-soft-gray-blue;
    height: 360px;
    width: 325px;
    padding: 20px;
    text-align: center;
    background-size: cover;
    position: relative;
    img {
      width: auto;
      margin: 0px;
      max-height: 60px;
      height: auto;
      max-width: 130px;
      //  padding-left: 10px;
    }
    h5 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &:hover:after {
      opacity: 0;
    }
    .tile-link {
      color: white;
      margin-top: 10px;
    }
    &:after {
      opacity: 1;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
    // .shopping-button {
    // this margin was removed as per https://maxwellhealth.atlassian.net/browse/MAXT-7910 (Long Product Name puts selected/confirmed button outside of tile) in hopes that would fix the button popping outside of the tile overlay frame and is then obscured as the overflow is set to hidden.
    // this is most likely a band-aid ~ jesse
    //   margin-top: 20px;
    // }
  }
  .hover-overlay.text-left {
    float: left;
    z-index: 998;
    position: absolute;
    background-color: rgba(11, 60, 73, 0.9);
    width: 100%;
    height: 100%;
    margin: -20px;
    padding: 20px;
    color: white;
    top: 20px;
    a:hover {
      color: white;
    }
  }
  .tile-with-image {
    padding: 10px;
    z-index: -100;
    margin: 40px 50px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .tile-overlay-alert {
    position: absolute;
    width: 325px;
    background-color: $maxwell-purple;
    padding: 10px;
    color: white;
    z-index: 1;
    font-size: 12px;
    margin-top: 5px;
  }
  .tile-overlay {
    float: left;
    background-color: rgba(11, 60, 73, 0.7);
    width: inherit;
    text-align: left;
    margin-left: -20px;
    padding: 6px 10px 12px;
    color: white;
    z-index: 10;
    min-height: 70px;
    height: initial;
    position: absolute;
    bottom: 0px;
    button {
      margin-top: -15px;
      margin-right: -4px;
    }
    &.big {
      min-height: 115px;
      height: initial;
    }
    &.bigbig {
      min-height: 155px;
      height: initial;
    }
  }
  .tile-overlay-left {
    float: left;
    width: 140px;
    font-size: 12px;
    font-weight: normal;
    strong {
      font-size: 14px;
    }
  }
  .tile-overlay-right {
    float: right;
    padding-top: 15px;
  }
  // .tile:hover {
  //   background-color: $shopping-soft-gray-bluer;
  // }

  &.wideTiling {
    .tile {
      width: 325px;
    }
  }
}

.tile:nth-child(even) {
  margin-right: 0px !important;
}

.product-form {
  margin: 20px;
}

.waiveAll {
  text-transform: none;
  position: relative;
  top: 10px;

  &:hover {
    color: white;
  }
}
// GRANDFATHERED PRODUCTS

.grandfather-products {
  margin-top: 10px;
  .grandfather-product {
    //padding: 15px;
    margin-top: 2px;
    margin-bottom: 2px;
    height: 100%;
    width: 100%;
    background-color: $shopping-soft-gray-blue;
    .title {
      background-color: #58acc1;
      padding: 15px;
      color: white;
      span {
        position: relative;
        top: -4px;
        left: 10px;
      }
    }
    .content {
      padding: 15px;
      text-align: center;
      .currently-enrolled {
        background-color: #83a243;
        color: white;
        display: block;
        margin: 0 auto 15px;
        width: 36%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .footer {
      background-color: rgba(11, 60, 73, 0.7);
      color: white;
      padding: 10px 0;
      display: flex;
      a {
        color: white;
        text-decoration: none;
      }
    }
    h4 {
      font-weight: 700;
      font-size: 16px;

      text-transform: uppercase;
    }
    .buttons {
      margin-right: 20px;
      margin-top: 5px;
    }
  }
}

// END GRANDFATHERED PRODUCTS

.workflowAction.shopping-button {
  width: 310px;
  font-size: 17px;
  margin-top: 10px;
  margin-right: 0px;
}

#arrow-review strong {
  margin-left: -12px;
  margin-right: 22px;
}
h3.panel-title {
  text-transform: uppercase;
}

.detailsrow {
  cursor: pointer;
}

.hover-overlay {
  cursor: pointer;
}
.productTags {
  margin-top: 30px;
  margin-bottom: 20px;
}
.productTag {
  background-color: #9b9b9b;
  color: #fff;
  padding: 5px;
  font-size: 8pt;
  border-radius: 5px;
  //float: left;
  margin-bottom: 12px;
  // width: 112px;
}

.beneficiary-container,
#beneficiary-container {
  // width: 975px;
  // margin-left: 30px;
  .header {
    margin-bottom: 20px;
  }
  .productRow > .row {
    margin-bottom: 20px;
  }
  .beneficiary-button-group {
    padding-left: 0;
    .shopping-button {
      margin-right: 20px;
    }
  }
}
