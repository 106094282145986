// @import "../../common/_colors.scss";

.products-noavailable {
  background: #f4f4f7;

  margin: 35px auto 0;
  padding: 1px 25px 30px;
  width: 550px;

  h2 {
    color: #0d596d;
    font-weight: 300;
    font-style: italic;
  }

  button {
    margin-top: 15px;
  }
}

.noavailable-custom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;
  padding: 40px;
  background-color: #fff;
  border: 1px solid $maxwell-purple;
  margin-top: 20px;
  margin-bottom: 20px;
  .flex-item {
    h2,
    p {
      margin: 0px;
    }
  }
  .box1 {
    width: 60%;
    &.box1-full-width {
      width: 100%;
    }
    strong {
      color: $maxwell-purple;
    }
  }
  .box2 {
    width: 40%;
    padding-left: 2%;
    margin-top: -15px;
    p:first-child {
      margin-bottom: 6px;
    }
    &.forms-complete {
      margin-top: 0px;
      text-align: right;
    }
  }
  h2 {
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: $maxwell-purple;
  }
  p {
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: black;
  }
  .triangle {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 44px 44px 0 0;
    border-color: $maxwell-purple transparent transparent transparent;
    font-size: 18px;
    font-weight: bold;
    color: white;
    .exclamation {
      position: absolute;
      left: 9px;
      top: -43px;
    }
  }
  .shopping-button.shopping-button-small {
    color: $maxwell-purple;
    border: 1px solid $maxwell-purple;
    background: transparent;
  }
  .emphasis {
    font-weight: 600;
    color: $maxwell-purple;
  }

  .forms-complete-button {
  }
}
