.benefit-summary {
}

.benefit-summary-header {
  h1 {
    // font-family: 'Lato Light Italic'; // where to get this?
    font-style: italic;
    font-size: 34px;
    font-weight: 300;
    color: $maxwell-dark-blue;
    text-transform: uppercase;
    text-align: center;
  }

  h3 {
    // font-family: 'Lato Italic'; // where to get this?
    font-style: italic;
    font-size: 24px;
    font-weight: 400;
    color: $maxwell-dark-blue;
    text-transform: uppercase;
  }

  .rocket {
    width: 190px;
    margin: 35px auto 0;

    img {
      width: 100%;
    }

    .summary-spinner {
      width: 100%;
      font-size: 60px;
    }
  }

  // Move to /ui
  .step {
    padding-left: 70px;
    padding-right: 60px;

    &:before {
      content: '1';
      position: absolute;
      top: 0;
      left: 25px;
      color: white;
      background-color: #5cb9d0;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      text-align: center;
      padding-top: 4px;
    }
    &:nth-child(1):before {
      content: '1';
    }
    &:nth-child(2):before {
      content: '2';
    }
    &:nth-child(3):before {
      content: '3';
    }

    .text-wrap {
      font-weight: 300;
    }

    // Move to /ui
    .btn {
      width: 100%;
      margin-top: 15px;
      background-color: #dc7f41;
      border: none;
      border-radius: 0;
      text-transform: uppercase;
      padding: 10px 0;
    }
  }

  .summary-header {
    margin-top: 60px;
    margin-bottom: 10px;

    h3 {
      margin: 0;
    }
  }

  .header-message {
    width: 470px;
    margin: 15px auto 0;

    p {
      font-size: 16px;
      font-weight: 400;
      color: $maxwell-dark-blue;
    }
  }
}
