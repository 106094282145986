.ReactModalPortal {
  position: relative;
  z-index: 1050;
  font-size: 15px;
  color: #474747;
}

// make react modal scrollable and the background not-scrollable, whilst removing the double scrollbar
.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content.ReactModal__Content--after-open.modal-dialog:focus {
  outline: none;
}

.ReactModal__Content .modal-header {
  margin-bottom: 20px;
}

.ReactModal__Content .no-button,
.ReactModal__Content .yes-button {
  color: white;
}

.ReactModal__Content .no-button,
.ReactModal__Content .delete-button {
  color: white;
}

.ReactModal__Content .delete-button {
  background-color: #ad3b4a;
}

.ReactModal__Content.working-on-it {
  width: 496px;
}

.modal-dialog.blooper {
  width: 400px;
}

.maxwell-confirmation-modal {
  padding: 50px 35px;
  background: #fff;
  border-radius: 0;

  .modal-content {
    box-shadow: none;
    border: 0;
  }

  .maxwell-confirmation-title {
    margin: 0 0 15px 0;
    color: $maxwell-blue;
    font-size: 20px;
  }

  .maxwell-confirmation-optional-action {
    float: left;
  }

  .maxwel-confirmation-description {
  }

  .maxwel-confirmation-yes {
    float: right;
    margin-top: 15px;
    &:hover {
      background: lighten($maxwell-blue, 20%);
    }
  }

  &.working-on-it {
    padding: 25px 30px 30px 30px;

    h1 {
      font-size: 34px;
      font-weight: 300;
      color: #0d596d;
      font-style: italic;
      line-height: 36px;
      margin-bottom: 10px;
      width: 90%;
    }

    .maxwel-confirmation-description {
      margin-top: 20px;
      margin-bottom: 25px;
    }

    p,
    span {
      line-height: 15px;
      font-size: 16px;
      color: #474747;
    }

    .maxwel-confirmation-yes {
      margin-top: 2px;
    }
  }
}

.modal-body {
  padding-bottom: 15px !important;
}
