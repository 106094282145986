/**
 * Overrides for slick slider
 */
.slick-slider {
  margin-bottom: 20px !important;
}
.slick-prev,
.slick-next {
  top: 50% !important;
  z-index: 10;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'Font Awesome 5 Free';
  font-size: 40px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.slick-prev {
  left: 15px;
  [dir='rtl'] & {
    left: auto;
    right: 15px;
  }
  &:before {
    content: '\f104';
    [dir='rtl'] & {
      content: '\f105';
    }
  }
}

.slick-next {
  right: 15px;
  [dir='rtl'] & {
    left: 15px;
    right: auto;
  }
  &:before {
    content: '\f105';
    [dir='rtl'] & {
      content: '\f104';
    }
  }
}

.remove-transform {
  text-transform: none;
}

#category-box {
  color: white;
  text-align: center;
  width: 335px;
  height: 320px;
  position: relative;
  @include linear-gradient(to top, #06303a, #175267);
  #category-header {
    height: 120px;
    margin-top: 10px;
    border-top: 1px solid $maxwell-soft-blue;
    border-bottom: 1px solid $maxwell-soft-blue;
    h2 {
      color: white;
    }
  }
  small {
    display: block;
    max-width: 240px;
    display: block;
    margin: 0 auto;
  }
  #button-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
  }
}

#bundlePopover {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  color: white;
  background: $maxwell-dark-blue;
  z-index: 10;
  .popoverWrapper {
    overflow: auto;
    padding: 10px 15px 15px 15px;
  }
  .bundleArrow {
    position: absolute;
    top: 10px;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid $maxwell-dark-blue;
  }

  /* NOTE: (andrew.smith) removed to allow for links in key-details and description
  h5, p, ul, li {
    pointer-events: none;
  }
  */

  h5 {
    color: white;
    text-transform: none;
    pointer-events: none;
    font-size: 16px;
    line-height: 1.3em;
    margin-top: 0;
  }

  ul {
    padding: 0;
    margin-bottom: 15px;
    li {
      padding: 10px 0;
      list-style-type: none;
      border-top: 1px solid $maxwell-soft-blue;
      &:last-child {
        border-bottom: 1px solid $maxwell-soft-blue;
      }
    }
  }
}

.bundle {
  background-color: #f4f4f7;
  margin-bottom: 30px;

  .break-word {
    word-wrap: break-word;
  }

  &.review-bundle {
    padding: 0;
    background-color: $shopping-background;
  }

  .spinner {
    height: 44px;
    width: 196px;
  }

  .header-wrapper {
    padding: 15px 15px 10px 15px;
  }

  .header,
  .updated-header {
    background-color: $maxwell-dark-blue;
    padding: 10px;

    &.row {
      margin: 0px;
    }

    h3 {
      margin-top: 10px;
      color: white;
      font-weight: 700;
      font-size: 20px;
    }
    p {
      margin-left: 15px;
      width: 70%;

      color: white;
    }
    .costs {
      font-size: 20px;
      color: white;
      margin-top: 10px;
    }
  }

  .updated-header {
    background-color: #f4f4f7;
    padding: 0 0 10px 0;
    h2 {
      color: $maxwell-dark-blue;
      margin-top: 5px;
    }
  }

  .tile-with-image {
    border-bottom: 1px solid $maxwell-soft-blue;

    & > .image-container {
      height: 70px;
      margin: 25px 0 10px 0;
    }
  }

  .body {
    // float: left;
    .details-sidebar {
      position: relative;

      .main-sidebar-content {
        // overflow-y: auto;
        overflow-y: auto;
        h5 {
          line-height: 1.4em;
          color: $maxwell-dark-blue;
        }

        ul {
          padding-left: 20px;
          color: $maxwell-dark-blue;
          li {
            margin-bottom: 10px;
            font-weight: 300;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .description {
          width: 225px;
        }
      }
    }

    .col-xs-3:first-child {
      padding-left: 0;
    }
    .item {
      border: 1px solid #d5d8e0;
      margin-bottom: 5px;
      padding: 10px;
      background: white;

      div {
        pointer-events: none;
      }
      .hoverClickText {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $maxwell-dark-blue;
        color: $maxwell-light-blue;
        font-size: 12px;
        font-weight: 300;
        font-style: italic;
        padding: 2px 0;
        display: none;
      }

      &.notSelected:hover .hoverClickText {
        display: block;
      }

      &.hoverState,
      &.notSelected:hover {
        border: 1px solid $maxwell-soft-blue;
        background: $maxwell-light-blue;
      }
    }
  }

  .footer {
    float: left;
    width: 100%;
  }
  .product-info {
    background-color: #dceaed;
    width: 100%;
  }
  .product-info-all {
    padding: 15px;
    padding-top: 10px;
    width: 100%;
    margin: 5px 0px;
  }
  .product-info-right {
    padding: 15px;
    width: 50%;
    vertical-align: top;
    background-color: #dceaed;
  }
  .product-info-left {
    padding: 15px;
    padding-top: 0px;
    width: 50%;
    vertical-align: top;
    background-color: #edf6f8;
    border-right: 5px solid #ffffff;
  }
  .product-info-all hr {
    margin-top: 10px;
    border-top: 1px solid rgb(51, 51, 51);
  }
  .bundle-products {
    min-width: 662px;
  }
  .header .add-to-cart {
    color: white;
    border-color: white;
    background-color: $shopping-button-primary-blue-background;
    &:hover {
      border: 2px solid $shopping-button-dark-blue-text;
      color: $shopping-button-dark-blue-text;
      background-color: $shopping-button-dark-blue-background;
    }
  }
}

.item {
  width: 237.5px;
  height: 250px;
  float: right;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &.not-featured-item {
    height: 120px;
  }

  .featured-namelong,
  .not-featured-namelong {
    font-weight: 300;
    color: $maxwell-dark-blue;
  }

  .featured-namelong {
    padding-top: 25px;
    margin: 0;
  }

  .not-featured-namelong {
    padding-top: 15px;
    text-align: left;
    margin: 0 -15px;
  }

  img {
    max-width: 165px;
    margin: 0px;
    padding-left: 10px;
  }

  // $maxwell-blue: #5db9d1;
  // $maxwell-soft-blue: #7fcee1;
  // $maxwell-light-blue: #e3f3f6;

  .tile-overlay {
    color: $maxwell-soft-blue;
  }
}
.product-image-row {
  height: 89px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 5px;
  padding: 1px 0;
  img {
    margin: 8px auto;
    display: block;
    padding: 5px 10px;
    max-height: 70px;
    background-color: rgba(255, 255, 255, 0.75);
    position: relative;
  }
}
.check-mark {
  padding: 5px 5px 5px 10px;
  background: #aaa;
  margin: -10px -10px -10px 10px;
  font-size: 18px;
  color: #666;
}
.check-mark.complete {
  color: white;
  background-color: #83a243;
}

#bundles-group,
#bundles {
  display: block;
}
#product-recommendation {
  padding-top: 20px;
  padding-bottom: 20px;
}

.addedToBundle {
  list-style-type: none;
  padding: 20px;
  background-color: $maxwell-light-green;
  border: 1px solid #b3c375;
}
.removedFromBundle {
  list-style-type: none;
  padding: 20px;
  background-color: $maxwell-light-red;
  border: 1px solid #d57f8e;
}

#carousel-container {
  width: 645px;
  margin-right: 20px;
  position: relative;

  .image-mask {
    /* IE 8 */
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=30)';

    /* IE 5-7 */
    filter: alpha(opacity=30);

    /* Netscape */
    -moz-opacity: 0.3;

    /* Safari 1.x */
    -khtml-opacity: 0.3;

    /* Good browsers */
    opacity: 0.3;

    background: black;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .image-text {
    word-wrap: break-word;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  #carousel {
    // color: white;
    // overflow: hidden;
    h1 {
      margin-top: 80px;
      width: 100%;
    }

    small {
      white-space: normal;
      display: block;
      max-width: 400px;
      display: block;
      margin: auto;
    }

    button {
      margin-top: 10px;
      &:hover {
        color: red;
      }
    }

    h1,
    small,
    .image-text,
    .image-mask {
      color: white;
      pointer-events: none;
    }

    .item {
      height: 320px;
      padding: 10px;
      width: 645px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

span.bigNumber {
  padding: 4px 10px;
  font-size: 18px;
  color: white;
  background-color: #1b586d;
  margin-right: 11px;
  float: left;
  margin-top: -5px;
  margin-bottom: 30px;
  width: 40px;
  text-align: center;
}
span.big-label {
  width: 68%;
  float: left;
}
.options-label {
  text-align: left !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
}
.slick-dots {
  li {
    margin: 0px 2px;
  }
  position: relative;
  margin-top: -446px;
  top: 0px;
  left: 0px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0px;
  width: inherit;
  float: right;
  margin-right: 14px;
}

.review-bundle {
  margin-bottom: 60px;
  // background-color: none;
  i.fa.fa-shopping-cart.fa-lg {
    margin-right: 8px;
  }
}

// font-style: italic;
// font-size: 18px;
// position:absolute;
// top:48%;
// right:0;bottom:0;left:0;
// margin:auto;
//
// -webkit-filter: blur(0);
// -moz-filter: blur(0);
// -o-filter: blur(0);
// -ms-filter: blur(0);
// filter: blur(0);
