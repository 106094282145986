.deferral.btn-info {
  background-color: $maxwell-blue;
  border-color: $maxwell-blue;
  color: #ffffff;
}

.deferral.btn-info:hover {
  background-color: $maxwell-soft-blue;
  border-color: $maxwell-soft-blue;
  color: #ffffff;
}

.deferral.btn-info:active {
  background-color: $maxwell-soft-blue;
  border-color: $maxwell-soft-blue;
  color: #ffffff;
}

.metrics-box {
  // background: white;
  // padding: 10px;
  height: 105px;
  margin: 0 20px 10px 20px;
  text-align: right;

  .title {
    display: block;
    // font-weight: bold;
    font-size: 16px;
  }
  .amount {
    display: block;
    font-size: 24px;
    line-height: 1.7em;
  }
  .as-of-date {
    font-size: 12px;
    color: #555;
  }

  border-bottom: 1px solid $maxwell-blue;
}

.deferral-amounts {
  h5 {
    font-weight: bold;
  }

  .deferral-amount-row {
    line-height: 1.8em;
  }

  hr {
    border-color: $shopping-soft-gray;
  }
}

.deferrals-box {
  margin-top: 25px;

  .btn.deferral {
    width: 37.5px;
  }

  .deferral {
    margin-top: 25px;
  }

  .bigNumber {
    margin-bottom: -10px !important;
  }
}
